import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { PlatformSettings } from 'app/core/platform/platform.types';
import { PlatformService } from 'app/core/platform/platform.service';
import { Intercom } from '@supy-io/ngx-intercom';
import { environment } from 'environments/environment';
import { AuthService } from 'app/core/auth/auth.service';
import { UserManagementService } from 'app/modules/partner/user-management/user-management.service';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { fuseAnimations } from '@fuse/animations';
import { AudioPlayerService } from 'app/shared/components/audio-player/audio-player.service';
import { LayoutService } from 'app/layout/layout.service';

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    styleUrls: ['./compact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CompactLayoutComponent implements OnInit, OnDestroy {
    user: User;
    adminUser: User;
    platform: PlatformSettings;
    isScreenSmall: boolean;
    navigation: Navigation;
    navigationItems: FuseNavigationItem[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        public _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public userService: UserService,
        public userManagementService: UserManagementService,
        public platformService: PlatformService,
        public intercom: Intercom,
        public authService: AuthService,
        private ccService: NgcCookieConsentService,
        public audioPlayerService: AudioPlayerService,
        public layoutService: LayoutService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    truncate(input, length): string {
        const ellipsis = '...';
        if (input.length > length + ellipsis) {
            return input.substring(0, length) + ellipsis;
        }
        return input;
    };

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /*
     <script>
    console.log("Intercom script loaded");
    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/bkace0p3'
    (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/bkace0p3'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
    </script>
     */

    ngOnInit(): void {
        // Subscribe to navigation data
        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
                this.prepareNavigationItems(this.user);
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this.userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                this.prepareNavigationItems(this.user);
                this._changeDetectorRef.markForCheck();
            });

        this.userService.adminUser$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.adminUser = user;
                this.prepareNavigationItems(this.user);
                this._changeDetectorRef.markForCheck();
            });

        this.platformService.platform$.pipe(takeUntil(this._unsubscribeAll)).subscribe(platform => {
            this.platform = platform;
            this.prepareNavigationItems(this.user);
            this._changeDetectorRef.markForCheck();
        });

        if (this.user && (this.user.role === "label" || this.user.role === "user") && environment.production) {
            let name = this.user.personalInformation?.firstName + " " + this.user.personalInformation?.lastName;
            if (!this.user.personalInformation.lastName) {
                name = "Unnamed User";
            }

            if (document.cookie.indexOf('cookieconsent_status=allow') > -1) {
                console.log("Intercom booting");
                this.intercom.boot({
                    user_id: this.user.uuid,
                    email: this.user.email,
                    name: name
                });
            } else {
                console.log("Intercom waiting for cookie consent");
            }

            this.ccService.statusChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(status => {
                console.log('Intercom cc status changed!', status);
                if (status.status === "allow") {
                    this.intercom.boot({
                        user_id: this.user.uuid,
                        email: this.user.email,
                        name: name
                    });
                }
            });
        }
    }

    prepareNavigationItems(user?: User): void {
        if (!this.user || !this.navigation || !this.platform) {
            return;
        }

        switch (this.user.role) {
            case "admin":
                this.navigationItems = this.navigation.adminCompact;
                break;
            case "partner":
                this.navigationItems = this.navigation.partnerCompact;
                break;
            case "user":
                this.navigationItems = this.navigation.userCompact;
                if (user?.permissions?.hasLabelSubscription === true) {
                    this.navigationItems = this.navigationItems.filter(item => item.id !== "artist-management");
                } else {
                    this.navigationItems = this.navigationItems.filter(item => item.id !== "label-management");
                }
                if (!this.platform.serviceRoles.includes(this.user.role)) {
                    this.navigationItems = this.navigationItems.filter(item => item.id !== "services");
                }
                break;
            case "label":
                this.navigationItems = this.navigation.userCompact;
                this.navigationItems = this.navigationItems.filter(item => item.id !== "artist-management");
                if (!this.platform.serviceRoles.includes(this.user.role)) {
                    this.navigationItems = this.navigationItems.filter(item => item.id !== "services");
                }
                this.navigationItems = this.navigationItems.filter(item => item.id !== "subscriptions");
                break;
            case "artist":
                //Intentional fallthrough
            case "marketing":
                this.navigationItems = this.navigation.userCompact;
                this.navigationItems = this.navigationItems.filter(item => ["dashboard", "analytics", "settings", "payments"].includes(item.id));
                break;
            default:
                console.error("Unknown user role: " + this.user.role);
        }
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(e: KeyboardEvent) {
        if ((e.metaKey || e.ctrlKey) && e.key === "d") {
            e.preventDefault();
            this.backToAdmin();
        }
    }

    backToAdmin() {
        let adminUser = null;
        this.userService.adminUser$.pipe(take(1)).subscribe({
            next: (user) => adminUser = user,
            error: (error) => console.error('User error:', error)
        });
        if (!this.authService.hasAdminAccess || !adminUser) {
            return;
        }

        this.authService.signInAsAdmin();
        this._router.navigate(['/']);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
