<!-- <div *ngIf="release" @slideInBottom @slideOutBottom
    class="audio-player w-full hidden sm:flex flex-row items-center justify-items-center justify-center gap-8 px-8 py-4">
    <div class="track-details flex flex-row items-center cursor-pointer" (click)="utilService.openReleaseModalWithUuid(release.uuid)">
        <div class="track-artwork items-center mr-2">
            <img *ngIf="release?.artwork" [alt]="'Release artwork'"
                [src]="platformService.getFileSrc(release?.artwork, 'artwork')" class="w-12 h-12">
        </div>
        <div *ngIf="release && track" class="track-info flex flex-col">
            <div class="track-title text-xl">{{track.title + (track.version ? " (" + track.version + ")" : "")}}</div>
            <div class="track-artist">{{releasesService.getArtistNamesForTrack(track)}}</div>
        </div>
    </div>
    <div class="controls flex flex-row gap-4 items-center">
        <mat-icon (click)="previousTrack()" [svgIcon]="'heroicons_solid:backward'"
            class="icon-size-10 cursor-pointer hidden lg:flex"></mat-icon>
        <mat-icon *ngIf="!waveSurferPlaying" (click)="playTrack($event)" color="primary" [svgIcon]="'heroicons_solid:play-circle'"
            class="icon-size-12 cursor-pointer" matTooltip="{{('play-track-tooltip' | transloco)}}"></mat-icon>
            <mat-icon *ngIf="waveSurferPlaying" (click)="pausePlayback($event)" color="primary" [svgIcon]="'heroicons_solid:pause-circle'"
            class="icon-size-12 cursor-pointer" matTooltip="{{('stop-playback-tooltip' | transloco)}}"></mat-icon>
        <mat-icon (click)="nextTrack()" [svgIcon]="'heroicons_solid:forward'"
            class="icon-size-10 cursor-pointer"></mat-icon>
    </div>
    <div class="current-play-position w-24 text-center font-semibold flex md:hidden flex-row gap-1 justify-between">
        <span class="text-primary-600 dark:text-primary-300 min-w-12">{{getTrackProgressString(track)}}</span>
        <span>&nbsp;/&nbsp;</span>
        <span class="min-w-12">{{getTrackLengthString(track)}}</span>
    </div>
    <div class="current-play-position w-12 text-center text-primary-600 dark:text-primary-300 font-semibold hidden md:flex">
        {{getTrackProgressString(track)}}
    </div>
    <div class="audio-player-waveform hidden md:flex flex-auto" id="waveform"></div>
    <div class="track-duration w-12 text-center font-semibold hidden md:flex">{{getTrackLengthString(track)}}</div>
    <mat-slider class="hidden lg:flex" (change)="onVolumeReleased($event)" min="0" max="100" step="0.1">
        <input [(ngModel)]="volume" (input)="onVolumeChanged($event)" matSliderThumb>
    </mat-slider>
    <button class="flex items-center" matSuffix mat-icon-button aria-label="Close" (click)="hidePlayer()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div *ngIf="release" @slideInBottom @slideOutBottom [ngClass]="{'fullscreen': isFullscreened, 'dark': !isFullscreened}"
    class="audio-player w-full flex sm:hidden flex-row items-center justify-items-center justify-between gap-4 px-4 py-2 rounded-lg transition-all">
    <div class="track-details flex flex-row items-center cursor-pointer" (click)="toggleFullscreen($event)">
        <div class="track-artwork items-center mr-2 w-14 h-14">
            <img *ngIf="release?.artwork" [alt]="'Release artwork'"
                [src]="platformService.getFileSrc(release?.artwork, 'artwork')" class="w-14 h-14">
        </div>
        <div *ngIf="release && track" class="track-info flex flex-col">
            <div class="track-title text-md">{{track.title + (track.version ? " (" + track.version + ")" : "")}}</div>
            <div class="track-artist text-sm">{{releasesService.getArtistNamesForTrack(track)}}</div>
        </div>
    </div>
    <div class="controls flex flex-row gap-4 items-center">
        <mat-icon *ngIf="!waveSurferPlaying" (click)="playTrack($event)" color="primary" [svgIcon]="'heroicons_solid:play-circle'"
            class="icon-size-14 cursor-pointer" matTooltip="{{('play-track-tooltip' | transloco)}}"></mat-icon>
            <mat-icon *ngIf="waveSurferPlaying" (click)="pausePlayback($event)" color="primary" [svgIcon]="'heroicons_solid:pause-circle'"
            class="icon-size-14 cursor-pointer" matTooltip="{{('stop-playback-tooltip' | transloco)}}"></mat-icon>
        <mat-icon (click)="nextTrack()" [svgIcon]="'heroicons_solid:forward'"
            class="icon-size-10 cursor-pointer"></mat-icon>
    </div>
</div>
-->

<div *ngIf="release" @slideInBottom @slideOutBottom  [ngClass]="{'sticky mb-6 bottom-6 w-9/10 mx-auto rounded-lg justify-between dark border-x-gray-500 b-1 custom-shadow px-4 py-2': layoutService.isSmall && !isFullscreened, 'bottom-0 w-full px-8 py-4': !layoutService.isSmall, 'fullscreen w-full flex-col my-auto': isFullscreened}"
    class="audio-player flex flex-row items-center justify-items-center justify-center gap-4 md:gap-8 transition-all" (swipe)="onSwipe($event)">
    <div class="track-details flex flex-row items-center cursor-pointer" (click)="!layoutService.isSmall ? utilService.openReleaseModalWithUuid(release.uuid) : toggleFullscreen($event)">
        <div class="track-artwork items-center">
            <img *ngIf="release?.artwork && !isFullscreened" [alt]="'Release artwork'"
                [src]="platformService.getFileSrc(release?.artwork, 'artwork')" class="w-12 h-12 mr-2">
        </div>
        <div *ngIf="release && track" [ngClass]="{'text-center': isFullscreened}" class="track-info flex flex-col min-w-36">
            <div [ngClass]="{'text-lg': !isFullscreened && layoutService.isSmall, 'text-xl': isFullscreened && !layoutService.isSmall, 'text-2xl': isFullscreened && layoutService.isSmall, 'text-hint': !track.title}" class="track-title font-semibold">{{(track.title ?? ('untitled-track' | transloco)) + (track.version ? " (" + track.version + ")" : "")}}</div>
            <div [ngClass]="{'text-sm': !isFullscreened && layoutService.isSmall, 'text-md': isFullscreened && !layoutService.isSmall, 'text-lg': isFullscreened && layoutService.isSmall}" class="track-artist">{{releasesService.getArtistNamesForTrack(track)}}</div>
        </div>
    </div>
    <div *ngIf="isFullscreened" class="track-artwork items-center flex w-64 h-64 shadow-2xl rounded-md">
        <img *ngIf="release?.artwork" [alt]="'Release artwork'"
            [src]="platformService.getFileSrc(release?.artwork, 'artwork')" class="w-64 h-64">
    </div>
    <div class="controls flex flex-row gap-4 items-center">
        <mat-icon (click)="previousTrack()" [svgIcon]="'heroicons_solid:backward'"
            class="icon-size-10 cursor-pointer lg:flex"
            [ngClass]="{'flex': isFullscreened || layoutService.isLarge, 'hidden': !layoutService.isLarge && !isFullscreened}"></mat-icon>
        <mat-progress-spinner *ngIf="waveSurferLoading" diameter="48" mode="indeterminate"
            class="inline-flex items-center cursor-wait"></mat-progress-spinner>
        <mat-icon *ngIf="!waveSurferLoading && !waveSurferPlaying" (click)="playTrack($event)" color="primary" [svgIcon]="'heroicons_solid:play-circle'"
            class="icon-size-12 cursor-pointer" matTooltip="{{('play-track-tooltip' | transloco)}}"></mat-icon>
        <mat-icon *ngIf="waveSurferPlaying" (click)="pausePlayback($event)" color="primary" [svgIcon]="'heroicons_solid:pause-circle'"
            class="icon-size-12 cursor-pointer" matTooltip="{{('stop-playback-tooltip' | transloco)}}"></mat-icon>
        <mat-icon (click)="nextTrack()" [svgIcon]="'heroicons_solid:forward'"
            class="icon-size-10 cursor-pointer"></mat-icon>
    </div>
    <div [ngClass]="{'flex': isFullscreened, 'hidden': layoutService.isSmall && !isFullscreened}" class="current-play-position text-center font-semibold sm:flex md:hidden flex-row gap-0 justify-between">
        <span class="text-primary-600 dark:text-primary-300 min-w-12">{{getTrackProgressString(track)}}</span>
        <span>&nbsp;/&nbsp;</span>
        <span class="min-w-12">{{getTrackLengthString(track)}}</span>
    </div>
    <div class="current-play-position w-12 text-center text-primary-600 dark:text-primary-300 font-semibold hidden md:flex">
        {{getTrackProgressString(track)}}
    </div>
    <div [ngClass]="{'w-full px-8': isFullscreened, 'hidden': layoutService.isSmall && !isFullscreened}" class="audio-player-waveform flex sm:flex-auto" id="waveform"></div>
    <div class="track-duration w-12 text-center font-semibold hidden md:flex">{{getTrackLengthString(track)}}</div>
    <mat-slider class="hidden lg:flex" (change)="onVolumeReleased($event)" min="0" max="100" step="0.1">
        <input [(ngModel)]="volume" (input)="onVolumeChanged($event)" matSliderThumb>
    </mat-slider>
    <button class="flex items-center"
    [ngClass]="{'visible absolute right-4 top-4 icon-size-8': isFullscreened, 'hidden': !isFullscreened && layoutService.isSmall}"
    matSuffix mat-icon-button aria-label="Close" (click)="!isFullscreened ? hidePlayer() : toggleFullscreen($event)">
        <mat-icon [ngClass]="{'icon-size-8': isFullscreened}">close</mat-icon>
    </button>

    <svg [ngClass]="{'flex': isFullscreened, 'hidden': !isFullscreened}" class="-z-1 absolute inset-0 print:hidden"
     height="100%" preserveAspectRatio="xMidYMax slice" viewBox="0 0 960 540" width="100%" xmlns="http://www.w3.org/2000/svg">
    <g class="opacity-40 text-gray-200 dark:text-gray-800" fill="none" stroke="currentColor" stroke-width="100">
        <circle cx="196" cy="23" r="234"></circle>
        <circle cx="790" cy="491" r="234"></circle>
    </g>
</svg>
</div>
