import { BooleanInput } from '@angular/cdk/coercion';
import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformService } from 'app/core/platform/platform.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { UserManagementService } from 'app/modules/partner/user-management/user-management.service';
import { Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;
    activeUsers: string;
    totalCount: number;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        public platformService: PlatformService,
        private _userManagementService: UserManagementService,
        private titleCasePipe: TitleCasePipe,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        if (this.user?.role === 'partner' || this.user?.role === 'admin') {
            timer(0, 60000).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                if (document.hasFocus()) {
                    this.getActiveUserCount();
                }
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this._router.navigate(['/sign-out']);
    }

    getActiveUserCount() {
        this._userManagementService.getActiveUserCount().subscribe(count => {
            this.activeUsers = "";
            this.totalCount = 0;
            for (let role of count) {
                this.activeUsers += `${this.titleCasePipe.transform(role.role)}: ${role.c}\n`
                let count = parseInt(role.c);
                if (!isNaN(count)) {
                    this.totalCount += count;
                }
            }
        });
    }
}
