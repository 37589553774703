import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { ShortcutsMockApi } from 'app/mock-api/common/shortcuts/api';

export const mockApiServices = [
    //AcademyMockApi,
    //ActivitiesMockApi,
    //CalendarMockApi,
    //ChatMockApi,
    //FileManagerMockApi,
    //HelpCenterMockApi,
    //IconsMockApi,
    //MessagesMockApi,
    //NotesMockApi,
    //SearchMockApi,
    //ScrumboardMockApi,
    //TasksMockApi,

    //ReleasesMockAPI,
    //NotificationsMockApi,
    //UserMockApi,
    //PlatformMockApi,
    //PartnerSettingsMockAPI,
    //AuthMockApi,
    //ReleaseApprovalMockAPI,
    //ServicesMockAPI,
    //SubscriptionsMockAPI,
    //PaymentsMockAPI,
    //DashboardMockAPI,
    //AnalyticsMockApi,

    //TODO: Decide what to do with these. Should probably stay client-side.
    NavigationMockApi,
    ShortcutsMockApi,
];
