<!-- Button -->
<!-- +(getActiveUserCount$() | async) -->
<button matTooltipClass="line-broken-tooltip tooltip-align-left"
    mat-icon-button
    [matTooltip]="user.role === 'admin' || user.role === 'partner' ?
    ('Active users\n' + activeUsers + '\nTotal: '+ totalCount) : undefined"
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <img
            [src]="platformService.getFileSrc(user.personalInformation.avatar, 'avatar')"
            draggable="false"
            *ngIf="showAvatar && user.personalInformation.avatar"
            class="w-8 h-8 rounded-full object-cover">
        <mat-icon
            *ngIf="!showAvatar || !user.personalInformation.avatar"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu class="w-90"
    [xPosition]="'before'"
    #userActions="matMenu">
    <div class="hover:bg-transparent cursor-default" mat-menu-item>
        <img
            *ngIf="showAvatar && user.personalInformation.avatar"
            [src]="platformService.getFileSrc(user.personalInformation.avatar, 'avatar')"
            class="w-12 h-12 mr-4 rounded-full object-cover"
            draggable="false">
        <span class="flex flex-col leading-none">
            <span>{{'signed-in-as' | transloco}}</span>
            <span class="mt-1.5 text-md font-medium">{{user.email}}</span>
        </span>
    </div>
    <mat-divider class="my-2"></mat-divider>
    <button *ngIf="user.role !== 'admin'" [routerLink]="(user.role === 'user' || user.role === 'label') ? ['/settings'] :  (user.role === 'partner' ? ['/partner/settings'] : [''])"
            mat-menu-item>
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{'profile' | transloco}}</span>
    </button>
    <mat-divider *ngIf="user.role !== 'admin'" class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>{{'sign-out' | transloco}}</span>
    </button>
</mat-menu>
