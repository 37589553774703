/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { ExtendedFuseNavigationItem } from 'app/layout/common/navigation/navigation.types';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    }
];

export const partnerCompactNavigation: ExtendedFuseNavigationItem[] = [
    {
        id: 'dashboard',
        meta: 'dashboard-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/partner/dashboard'
    },
    {
        id: 'user-management',
        meta: 'user-management',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/partner/user-management'
    },
    {
        id: 'release-approval',
        meta: 'release-approval',
        type: 'basic',
        icon: 'heroicons_outline:music-note',
        link: '/partner/release-approval',
        badges: [
            {
                id: "normal",
                title: '0',
                classes: 'bg-green-700 text-white rounded-full w-4 h-4',
                hidden: true,
                tooltip: "Pending Normal Deliveries"
            },
            {
                id: "express",
                title: '0',
                classes: 'bg-red-700 text-white rounded-full w-4 h-4',
                hidden: true,
                tooltip: "Pending Express Deliveries"
            }
        ]
    },
    {
        id: 'label-management',
        meta: 'label-management-page-title',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/partner/labels'
    },
    {
        id: 'analytics',
        meta: 'analytics-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/partner/analytics'
    },
    {
        id: 'service-management',
        meta: 'services-page-title',
        type: 'basic',
        icon: 'heroicons_outline:view-grid-add',
        link: '/partner/service-management',
        badge: {
            title: '0',
            classes: 'bg-green-700 text-white rounded-full w-5 h-5',
            hidden: true
        },
    },
    {
        id: 'payments',
        meta: 'payments-page-title',
        type: 'basic',
        icon: 'heroicons_outline:currency-euro',
        link: '/partner/payments',
        badge: {
            title: '0',
            classes: 'bg-green-700 text-white rounded-full w-5 h-5',
            hidden: true
        },
    },
    {
        id: 'royalties',
        meta: 'royalties-page-title',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/partner/royalties',
        badge: {
            title: '0',
            classes: 'bg-green-700 text-white rounded-full w-5 h-5',
            hidden: true
        },
    },
    {
        id: 'subscription-management',
        meta: 'subscription-page-title',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: '/partner/subscription-management'
    },
    {
        id: 'partner-settings',
        meta: 'partner-settings-page-title',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/partner/settings'
    },
];

export const adminCompactNavigation: ExtendedFuseNavigationItem[] = [
    {
        id: 'admin-dashboard',
        meta: 'dashboard-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/admin/dashboard'
    },
    {
        id: 'user-management',
        meta: 'user-management',
        type: 'basic',
        icon: 'heroicons_outline:users',
        link: '/admin/user-management'
    },
    {
        id: 'release-approval',
        meta: 'release-approval',
        type: 'basic',
        icon: 'heroicons_outline:music-note',
        link: '/admin/release-approval',
        badges: [
            {
                id: "normal",
                title: '0',
                classes: 'bg-green-700 text-white rounded-full w-4 h-4',
                hidden: true,
                tooltip: "Pending Normal Deliveries"
            },
            {
                id: "express",
                title: '0',
                classes: 'bg-red-700 text-white rounded-full w-4 h-4',
                hidden: true,
                tooltip: "Pending Express Deliveries"
            }
        ]
    },
    {
        id: 'analytics',
        meta: 'analytics-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/admin/analytics'
    },
    {
        id: 'payments',
        meta: 'payments-page-title',
        type: 'basic',
        icon: 'heroicons_outline:currency-euro',
        link: '/admin/payments'
    },
    {
        id: 'royalties',
        meta: 'royalties-page-title',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/admin/royalties'
    },
    {
        id: 'partner-management',
        meta: 'partner-management-page-title',
        type: 'basic',
        icon: 'heroicons_outline:office-building',
        link: '/admin/partner-management'
    },
    {
        id: 'maintenance',
        meta: 'maintenance-page-title',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/admin/maintenance'
    },
];

export const userCompactNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        meta: 'dashboard-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/dashboard'
    },
    {
        id: 'releases',
        meta: 'releases-page-title',
        type: 'basic',
        icon: 'heroicons_outline:music-note',
        link: '/releases'
    },
    {
        id: 'label-management',
        meta: 'label-management-page-title',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/labels'
    },
    {
        id: 'artist-management',
        meta: 'artist-management-page-title',
        type: 'basic',
        icon: 'heroicons_outline:star',
        link: '/artists'
    },
    {
        id: 'analytics',
        meta: 'analytics-page-title',
        type: 'basic',
        icon: 'heroicons_outline:chart-bar',
        link: '/analytics'
    },
    {
        id: 'subscriptions',
        meta: 'subscription-page-title',
        type: 'basic',
        icon: 'heroicons_outline:shopping-cart',
        link: '/subscriptions'
    },
    {
        id: 'services',
        meta: 'services-page-title',
        type: 'basic',
        icon: 'heroicons_outline:view-grid-add',
        link: '/services'
    },
    {
        id: 'payments',
        meta: 'payments-page-title',
        type: 'basic',
        icon: 'heroicons_outline:currency-dollar',
        link: '/payments'
    },

    {
        id: 'settings',
        meta: 'settings-page-title',
        type: 'basic',
        icon: 'heroicons_outline:cog',
        link: '/settings'
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
