import { MatPaginatorIntl } from '@angular/material/paginator';
import { translate } from '@ngneat/transloco';

const rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
        return translate('paginator.item-x-of-y', { x: 0, y: length });
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
        startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;

    return translate('paginator.item-x-to-y-of-z', { x: startIndex + 1, y: endIndex, z: length });
};

export function getPaginatorIntl(intl: MatPaginatorIntl) {
    const paginatorIntl = intl;

    paginatorIntl.itemsPerPageLabel = translate('paginator.items-per-page-colon');
    paginatorIntl.nextPageLabel = translate('paginator.next-page');
    paginatorIntl.previousPageLabel = translate('paginator.previous-page');
    paginatorIntl.firstPageLabel = translate('paginator.first-page');
    paginatorIntl.lastPageLabel = translate('paginator.last-page');
    paginatorIntl.getRangeLabel = rangeLabel;

    return paginatorIntl;
}
