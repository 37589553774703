import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, ErrorHandler, Inject, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterConfigOptions, RouterFeatures, RouterModule, provideRouter, withDebugTracing, withInMemoryScrolling, withPreloading, withRouterConfig } from '@angular/router';
import { provideFuse } from '@fuse';
import { TRANSLOCO_CONFIG, TRANSLOCO_FALLBACK_STRATEGY, TRANSLOCO_INTERCEPTOR, TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER, TRANSLOCO_SCOPE, Translation, TranslocoConfig, TranslocoFallbackStrategy, TranslocoMissingHandler, TranslocoService, translocoConfig, provideTransloco } from '@ngneat/transloco';
import { APIInterceptor } from 'app/api.interceptor';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { mockApiServices } from 'app/mock-api';
import { getPaginatorIntl } from 'app/paginator.intl';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { GlobalErrorHandler } from './error-handler';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { provideTranslocoPersistLang } from '@ngneat/transloco-persist-lang';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import languages from 'assets/i18n/languages.json';
import { environment } from 'environments/environment';
import { lastValueFrom } from 'rxjs';
import { DefaultInterceptor, TranslocoInterceptor } from '@ngneat/transloco/lib/transloco.interceptor';
import * as Hammer from 'hammerjs';

export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any> {
      swipe: { direction: Hammer.DIRECTION_ALL },
    };
  }

@Injectable()
export class MyIntl extends TimeagoIntl {
    // do extra stuff here...
}

@Injectable()
export class CustomMissingHandler implements TranslocoMissingHandler {
    handle(key: string, config: TranslocoConfig) {
        return key;
    }
}

@Injectable()
export class CustomTranslocoInterceptor implements TranslocoInterceptor {
    preSaveTranslation(translation: Translation): Translation {
        return translation;
    }

    preSaveTranslationKey(_: string, value: string): string {
        return value;
    }
}

@Injectable()
export class CustomTranslocoFallbackStrategy implements TranslocoFallbackStrategy {
    constructor(@Inject(TRANSLOCO_CONFIG) private userConfig: TranslocoConfig) { }

    getNextLangs() {
        const fallbackLang = this.userConfig.fallbackLang;
        if (!fallbackLang) {
            throw new Error(
                'When using the default fallback, a fallback language must be provided in the config!'
            );
        }

        return Array.isArray(fallbackLang) ? fallbackLang : [fallbackLang];
    }
}

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    preloadingStrategy: PreloadAllModules,
    enableTracing: false
};

const cookieConfig: NgcCookieConsentConfig = {
    autoAttach: true,
    content: {},
    cookie: {
        domain: 'app.distro-beat.com'
    },
    palette: {
        popup: {
            background: '#000'
        },
        button: {
            background: '#4c42d7'
        }
    },
    theme: 'edgeless',
    type: 'opt-in',
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        NgcCookieConsentModule.forRoot(cookieConfig),

        HammerModule,

        TimeagoModule.forRoot({
            intl: { provide: TimeagoIntl, useClass: MyIntl },
            formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
        }),
    ],
    providers: [
        /* provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules) ,
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
        ), */
        provideTransloco({
            config: {
                availableLangs: languages,
                defaultLang: 'eng',
                fallbackLang: 'eng',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            },
            loader: TranslocoHttpLoader,
        }),
        provideTranslocoMessageformat(),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService],
            useFactory: (translocoService: TranslocoService): any => (): Promise<Translation> => {
                const lang = localStorage.getItem("translocoLang");
                if (!lang || lang.length !== 3) {
                    const defaultLang = translocoService.getDefaultLang();
                    translocoService.setActiveLang(defaultLang);
                    return lastValueFrom(translocoService.load(defaultLang));
                } else {
                    return lastValueFrom(translocoService.load(lang));
                }
            },
            multi: true
        },
        provideTranslocoPersistLang({
            storage: {
                useValue: localStorage,
            },
        }),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'compact',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    {
                        id: 'theme-default',
                        name: 'Default',
                    },
                    {
                        id: 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id: 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id: 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id: 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id: 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
        {
            provide: LOCALE_ID,
            deps: [TranslocoService],
            useFactory: (service: TranslocoService) => {
                /* let locale = service.getCurrentLocale();
                console.log('locale: ' + locale)
                if (locale === 'eng' || locale.startsWith('en-')) {
                    return 'en-US';
                } else if (locale === 'deu' || locale.startsWith('de-')) {
                    registerLocaleData(localeDe);
                    return 'de-DE';
                } */
                if (service.getActiveLang() === 'deu') {
                    registerLocaleData(localeDe);
                    return 'de-DE';
                }

                return 'en-US';
            }
        },
        {
            provide: MatPaginatorIntl,
            deps: [TranslocoService],
            useFactory: (service: TranslocoService) => {
                let paginatorIntl = new MatPaginatorIntl();

                service.events$.subscribe((event) => {
                    if ((event.type === 'translationLoadSuccess' && event.payload.scope === null && event.payload.langName === service.getActiveLang()) || event.type === 'langChanged') {
                        getPaginatorIntl(paginatorIntl);
                        paginatorIntl.changes.next();
                    }
                });

                getPaginatorIntl(paginatorIntl);
                paginatorIntl.changes.next();

                return paginatorIntl;
            }
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        {
            provide: MAT_DATE_LOCALE,
            deps: [TranslocoService],
            useFactory: (service: TranslocoService) => {
                if (service.getActiveLang() === 'deu') {
                    return 'de-DE';
                }

                return 'en-US';
            }
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true,
        },
        {
            provide: TRANSLOCO_SCOPE,
            useValue: ['lang', 'cc']
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
          }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}


