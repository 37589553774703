import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { first } from 'rxjs/operators';
import { AvailableLangs, LangDefinition, TranslocoService } from '@ngneat/transloco';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import languages from 'assets/i18n/languages.json';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector       : 'languages',
    templateUrl    : './languages.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'languages'
})

export class LanguagesComponent implements OnInit, OnDestroy
{
    availableLangs: LangDefinition[];
    activeLang: string;
    flagCodes: any;


    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _breadcrumbsService: BreadcrumbService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs() as LangDefinition[];

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            this.activeLang = activeLang;
            this._updateNavigation(activeLang);
            this._updateBreadcrumbs(activeLang);
        });

        // Set the country iso codes for languages for flags
        this.flagCodes = Object.assign({}, ...languages.map(lang => ({[lang.id]: lang.flag})));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void
    {
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any
    {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');
        if (!navComponent) {
            return null;
        }

        const navigation = navComponent.navigation;

        navigation.forEach(navItem => {
            this._translate(navItem.meta, (translation) => {
                navItem.title = translation;
                navComponent.refresh();
            });
        })

    }

    private _updateBreadcrumbs(lang: string): void {
        this._breadcrumbsService.breadcrumbs$.pipe(first()).subscribe(breadcrumbs => {
            if (!breadcrumbs || breadcrumbs.length === 0) {
                return;
            }
            const lastBreadCrumb = breadcrumbs[breadcrumbs.length - 1]
            const route = lastBreadCrumb.routeLink;
            this._breadcrumbsService.set(route, lastBreadCrumb);
        });
    }

    private _translate(key: string, callback: (string) => void) {
        this._translocoService.selectTranslate(key).pipe(first())
            .subscribe((translation) => {
                callback(translation);
            });
    }
}
