import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { PlatformService } from 'app/core/platform/platform.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UtilService } from 'app/shared/util.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _navigationService: NavigationService,
        private _notificationsService: NotificationsService,
        private _shortcutsService: ShortcutsService,
        private _platformService: PlatformService,
        private _utilService: UtilService,
        private _router: Router,
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            this._notificationsService.getAll(),
            this._shortcutsService.getAll(),
            this._utilService.getLanguages(),
            this._utilService.getCountries(),

            //OPTIMIZE This causes a second request for platform settings each time the page is loaded, but fixes the issue where the settings are not refreshed after a login.
            //The exposed properties change based on the role of the authenticated user.
            this._platformService.get(),
        ]).pipe(catchError((err, caught) => {
            console.error('InitialData error:');
            console.error(err);
            if (err.status === 401) {
                this._router.navigate(['/sign-in']);
            } else if (err.status >= 400) {
                this._router.navigate(['/sign-out']);
            } else {
                //this._router.navigate(['/sign-in']);
            }
            return of(true);
        }));
    }
}
