import { NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsActiveMatchOptions, RouterLink, RouterLinkActive } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseUtilsService } from '@fuse/services/utils/utils.service';
import { UserService } from 'app/core/user/user.service';
import { ExtendedFuseNavigationItem } from 'app/layout/common/navigation/navigation.types';
import { ReleaseApprovalService } from 'app/modules/partner/release-approval/release-approval.service';
import { PaymentsService } from 'app/modules/user/payments/payments.service';
import { ServicesService } from 'app/modules/user/services/services.service';
import { Subject, takeUntil, timer } from 'rxjs';

@Component({
    selector: 'fuse-vertical-navigation-basic-item',
    templateUrl: './basic.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgIf, RouterLink, RouterLinkActive, MatTooltipModule, NgTemplateOutlet, MatIconModule, NgForOf],
})
export class FuseVerticalNavigationBasicItemComponent implements OnInit, OnDestroy {
    @Input() item: ExtendedFuseNavigationItem;
    @Input() name: string;

    isActiveMatchOptions: IsActiveMatchOptions;
    private _fuseVerticalNavigationComponent: FuseVerticalNavigationComponent;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseUtilsService: FuseUtilsService,
        private _userService: UserService,
        private _releaseApprovalService: ReleaseApprovalService,
        private _paymentsService: PaymentsService,
        private _servicesService: ServicesService,
    ) {
        // Set the equivalent of {exact: false} as default for active match options.
        // We are not assigning the item.isActiveMatchOptions directly to the
        // [routerLinkActiveOptions] because if it's "undefined" initially, the router
        // will throw an error and stop working.
        this.isActiveMatchOptions = this._fuseUtilsService.subsetMatchOptions;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Set the "isActiveMatchOptions" either from item's
        // "isActiveMatchOptions" or the equivalent form of
        // item's "exactMatch" option
        this.isActiveMatchOptions =
            this.item.isActiveMatchOptions ?? this.item.exactMatch
                ? this._fuseUtilsService.exactMatchOptions
                : this._fuseUtilsService.subsetMatchOptions;

        // Get the parent navigation component
        this._fuseVerticalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

        // Mark for check
        this._changeDetectorRef.markForCheck();

        // Subscribe to onRefreshed on the navigation component
        this._fuseVerticalNavigationComponent.onRefreshed.pipe(
            takeUntil(this._unsubscribeAll),
        ).subscribe(() => {
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        if (this.item.id === 'release-approval') {
            this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
                if (!user || (user.role !== 'partner' && user.role !== "admin")) {
                    return;
                }

                this._releaseApprovalService.pendingCount$.pipe(
                    takeUntil(this._unsubscribeAll)
                ).subscribe(counts => {
                    let navItem = this.item;
                    if (navItem?.badges) {
                        let badge = navItem.badges.find(b => b.id === 'normal');
                        badge.title = counts.normal.toString();
                        if (counts.normal > 0) {
                            badge.hidden = false;
                        } else {
                            badge.hidden = true;
                        }

                        badge = navItem.badges.find(b => b.id === 'express');
                        badge.title = counts.express.toString();
                        if (counts.express > 0) {
                            badge.hidden = false;
                        } else {
                            badge.hidden = true;
                        }
                    }
                    this._changeDetectorRef.markForCheck();
                });

                //Auto-update pending count
                timer(0, 120000).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                    if (document.hasFocus()) {
                        this._releaseApprovalService.getPendingReleasesCount().subscribe();
                    }
                });
            });
        }

        if (this.item.id === 'payments') {
            this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
                if (!user || user.role !== 'partner') {
                    return;
                }

                this._paymentsService.pendingCount$.pipe(
                    takeUntil(this._unsubscribeAll)
                ).subscribe(count => {
                    let navItem = this.item;
                    if (navItem?.badge) {
                        navItem.badge.title = count.toString();
                        if (count > 0) {
                            navItem.badge.hidden = false;
                        } else {
                            navItem.badge.hidden = true;
                        }
                    }
                    this._changeDetectorRef.markForCheck();
                });

                //Auto-update pending count
                timer(0, 120000).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                    if (document.hasFocus()) {
                        this._paymentsService.getPendingPayoutsCount().subscribe();
                    }
                });
            });
        }

        if (this.item.id === 'service-management') {
            this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
                if (!user || user.role !== 'partner') {
                    return;
                }

                this._servicesService.pendingCount$.pipe(
                    takeUntil(this._unsubscribeAll)
                ).subscribe(count => {
                    let navItem = this.item;
                    if (navItem?.badge) {
                        navItem.badge.title = count.toString();
                        if (count > 0) {
                            navItem.badge.hidden = false;
                        } else {
                            navItem.badge.hidden = true;
                        }
                    }
                    this._changeDetectorRef.markForCheck();
                });

                //Auto-update pending count
                timer(0, 120000).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
                    if (document.hasFocus()) {
                        this._servicesService.getPendingPayoutsCount().subscribe();
                    }
                });
            });
        }

        if (this.item.id === 'subscriptions') {
            this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
                if (!user || (user.role !== 'user')) {
                    return;
                }

                this._paymentsService.expiringSubscriptions$.pipe(
                    takeUntil(this._unsubscribeAll)
                ).subscribe(subs => {
                    if (!subs?.length) {
                        return;
                    }
                    console.log(subs);
                    let navItem = this.item;
                    if (navItem?.badge) {
                        navItem.badge.title = subs.length.toString();
                        console.log(subs.length);
                        if (subs.length > 0) {
                            navItem.badge.hidden = false;
                        } else {
                            navItem.badge.hidden = true;
                        }
                    }
                    this._changeDetectorRef.markForCheck();
                });
            });
        }

        if (this.item.id === 'analytics') {
            this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
                if (!user) {
                    return;
                }

                //this.item.hidden = () => !user.permissions.trendsAllowed;
                this._changeDetectorRef.markForCheck();
            });
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
