[
    {
        "id"   : "eng",
        "flag" : "US",
        "label": "English"
    },
    {
        "id"   : "deu",
        "flag" : "DE",
        "label": "Deutsch"
    }
]
