import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltip } from '@angular/material/tooltip';
import { fuseAnimations } from '@fuse/animations';
import { FuseConfigService } from '@fuse/services/config';
import { TranslocoPipe } from '@ngneat/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { PlatformService } from 'app/core/platform/platform.service';
import { LayoutService } from 'app/layout/layout.service';
import { ReleasesService } from 'app/modules/user/releases/releases.service';
import { Release, Track } from 'app/modules/user/releases/releases.types';
import { AudioPlayerService } from 'app/shared/components/audio-player/audio-player.service';
import { DurationPipe } from 'app/shared/shared.directives';
import { UtilService } from 'app/shared/util.service';
import { environment } from 'environments/environment';
import { Subject, fromEvent, take, takeUntil, timer } from 'rxjs';
import WaveSurfer from 'wavesurfer.js';

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
    standalone: true,
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MatIcon, MatTooltip, TranslocoPipe, NgIf, MatInput, MatSliderModule, FormsModule, NgClass, MatProgressSpinnerModule],
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
    audioPlayer: HTMLAudioElement;
    isPlaying: boolean;
    abortController = new AbortController();

    release: Release;
    track: Track;

    volume: number = 70;

    waveSurfer: WaveSurfer;
    waveSurferTrackId: string;
    waveSurferUrl: string;
    waveSurferPlaying: boolean;
    waveSurferLoading: boolean;
    isFullscreened: boolean;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public platformService: PlatformService,
        public releasesService: ReleasesService,
        private _authService: AuthService,
        private durationPipe: DurationPipe,
        private _ngZone: NgZone,
        private _changeDetectorRef: ChangeDetectorRef,
        public audioPlayerService: AudioPlayerService,
        public utilService: UtilService,
        public layoutService: LayoutService,
        @Inject(DOCUMENT) private document: Document
    ) {
    }

    ngOnInit() {
        this.audioPlayer = new Audio();
        this.isPlaying = false;

        this.audioPlayerService.track$.pipe(takeUntil(this._unsubscribeAll)).subscribe((track) => {
            if (!track) {
                return;
            }
            this.track = track;
            this._changeDetectorRef.detectChanges();
            this.playTrack(null);
        });

        this.audioPlayerService.release$.pipe(takeUntil(this._unsubscribeAll)).subscribe((release) => {
            if (!release) {
                return;
            }
            this.release = release;
            this.audioPlayerService.setVisibility(true);
        });

        this.audioPlayerService.isVisible$.pipe(takeUntil(this._unsubscribeAll)).subscribe((visibility) => {
            if (visibility) {
                this.document.body.classList.add('audio-player-open');
            } else {
                this.document.body.classList.remove('audio-player-open');
            }
        });

        if (localStorage.getItem('volume')) {
            this.volume = parseInt(localStorage.getItem('volume'));
        }

        timer(1000, 500).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            if (this.waveSurfer && this.waveSurfer.isPlaying()) {
                this._changeDetectorRef.markForCheck();
            }
        });

        /* if (!environment.production) {
            console.log("AudioPlayerComponent: ngOnInit");
            this.releasesService.getReleaseById("48917459-59fe-4898-a95f-3ac37d9988c6").subscribe(release => {
                this.audioPlayerService.setRelease(release);
                this.track = release.tracks[0];
                this.audioPlayerService.setVisibility(true);
                this._changeDetectorRef.detectChanges();
                console.log(this.track);
            });
        } */

        fromEvent(window, 'resize').pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this.audioPlayer) {
            this.audioPlayer.pause();
            this.audioPlayer = null;
        }
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.removeWaveSurfer();
    }

    hidePlayer() {
        this.removeWaveSurfer();
        this.audioPlayerService.setVisibility(false);
        this.release = null;
        this.track = null;
        this._changeDetectorRef.markForCheck();
    }

    toggleFullscreen(event?: any) {
        this.isFullscreened = !this.isFullscreened;
        this._changeDetectorRef.markForCheck();
    }

    onVolumeReleased(event: any) {
        console.log(event);
        localStorage.setItem('volume', this.volume.toString());
    }

    onVolumeChanged(event: any) {
        this.audioPlayer.volume = this.volume / 100;
        this.waveSurfer.setVolume(this.volume / 100);
        this._changeDetectorRef.markForCheck();
    }

    playTrack(event: MouseEvent) {
        event?.stopPropagation();
        event?.preventDefault();

        let track: Track;
        this.audioPlayerService.track$.pipe(take(1)).subscribe((t) => track = t);
        if (this.waveSurfer && this.waveSurferTrackId === track.uuid && this.waveSurferUrl === this.platformService.getAudioPreviewSrc(track)) {
            this.waveSurferLoading = false;
            this.waveSurfer.play();
            return;
        }

        this.removeWaveSurfer();

        this.waveSurfer = WaveSurfer.create({
            container: '.audio-player-waveform',
            waveColor: 'grey',
            progressColor: this.platformService.getAccentColor(),
            height: 60,
            hideScrollbar: true,
            fillParent: true,
            interact: true,
            dragToSeek: true,
            cursorWidth: 2,
            fetchParams: {

                headers: [
                    ["Authorization", "Bearer " + this._authService.accessToken]
                ],
                signal: this.abortController.signal
            }
        });
        this.waveSurfer.setVolume(this.volume / 100);
        this.waveSurferLoading = true;
        this.waveSurferTrackId = track.uuid;
        this.waveSurferUrl = this.platformService.getAudioPreviewSrc(track);

        this.waveSurfer.load(this.waveSurferUrl);
        this.waveSurfer.on('ready', () => {
            this._ngZone.run(() => {
                this.waveSurferLoading = false;
                this._changeDetectorRef.detectChanges();
            });
            this.waveSurfer.play();
        });

        this.waveSurfer.on('play', () => {
            this._ngZone.run(() => {
                this.waveSurferPlaying = true;
                this._changeDetectorRef.detectChanges();
            });
        });

        this.waveSurfer.on('pause', () => {
            this._ngZone.run(() => {
                this.waveSurferPlaying = false;
                this._changeDetectorRef.detectChanges();
            });
        });

        this.waveSurfer.on('finish', () => {
            this._ngZone.run(() => {
                this.waveSurferPlaying = false;
                this._changeDetectorRef.detectChanges();
            });
        });

        this.waveSurfer.on('interaction', () => {
            this._ngZone.run(() => {
                this._changeDetectorRef.markForCheck();
            });
        });

        this._changeDetectorRef.markForCheck();
    }

    onSwipe(evt) {
        if (!this.layoutService.isSmall) {
            return;
        }

        const threshold = 40;
        if (evt.deltaY > threshold) {
            if (this.isFullscreened) {
                this.toggleFullscreen();
            } else {
                this.hidePlayer();
            }
        } else if (evt.deltaY < -threshold) {
            if (!this.isFullscreened) {
                this.toggleFullscreen();
            }
        } else if (evt.deltaX > threshold) {
            this.previousTrack();
        } else if (evt.deltaX < -threshold) {
            this.nextTrack();
        }
        evt.preventDefault();
    }

    nextTrack() {
        this.audioPlayerService.playNext();
    }

    previousTrack() {
        this.audioPlayerService.playPrevious();
    }

    getTrackProgressString(track: Track): string {
        let progress = this.waveSurfer?.getCurrentTime() || 0;
        return `${this.durationPipe.transform(progress)}`;
    }

    getTrackLengthString(track: Track): string {
        let length = track.audioFile?.duration;
        return `${this.durationPipe.transform(length ?? 0)}`;
    }


    pausePlayback(event: MouseEvent) {
        event?.stopPropagation();
        event?.preventDefault();
        if (this.waveSurfer?.isPlaying()) {
            this.waveSurfer.pause();
            this.waveSurferPlaying = false;
        } else {
            this.waveSurfer.play();
            this.waveSurferPlaying = true;
        }

        this._changeDetectorRef.markForCheck();
    }

    removeWaveSurfer() {
        if (this.waveSurfer) {
            this.waveSurferPlaying = false;
            this.waveSurferUrl = undefined;
            this.waveSurferTrackId = undefined;
            try {
                if (this.waveSurferLoading && this.abortController) {
                    this.abortController.abort("Abort waveSurfer");
                }
                this.waveSurfer.stop();
            } catch (e) {
                console.warn(e);
            }
            this.waveSurfer.destroy();
            this.waveSurfer = undefined;
        }
    }
}
