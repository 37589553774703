import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import { IntercomModule } from '@supy-io/ngx-intercom';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { CompactLayoutModule } from 'app/layout/layouts/vertical/compact/compact.module';
import { SharedModule } from 'app/shared/shared.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Horizontal navigation
    /*CenteredLayoutModule,
    EnterpriseLayoutModule,
    MaterialLayoutModule,
    ModernLayoutModule,*/

    // Vertical navigation
    /*ClassicLayoutModule,
    ClassyLayoutModule,*/
    CompactLayoutModule,
    /*DenseLayoutModule,
    FuturisticLayoutModule,
    ThinLayoutModule*/
];

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports     : [
        IntercomModule.forRoot({
            appId: 'bkace0p3',
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
          }),
        MatIconModule,
        MatTooltipModule,
        FuseDrawerComponent,
        SharedModule,
        FuseLoadingBarComponent,
        ...layoutModules
    ],
    exports     : [
        LayoutComponent,
        ...layoutModules
    ]
})
export class LayoutModule
{
}
