import { Shortcut } from "app/layout/common/shortcuts/shortcuts.types";

/* eslint-disable */
export const shortcuts: Shortcut[] = [
    {
        id         : 'a1ae91d3-e2cb-459b-9be9-a184694f548b',
        label      : 'Changelog',
        description: 'List of changes',
        icon       : 'heroicons_outline:clipboard-document-list',
        link       : 'https://distrobeat.upfeed.co',
        newTab     : true,
        useRouter  : false
    },
    {
        id         : '989ce876-c177-4d71-a749-1953c477f825',
        label      : 'Documentation',
        description: 'Visit our knowledge base',
        icon       : 'heroicons_outline:book-open',
        link       : 'https://help.distro-beat.com/',
        newTab     : true,
        useRouter  : false
    },
    /* {
        id         : '2496f42e-2f25-4e34-83d5-3ff9568fd984',
        label      : 'Help center',
        description: 'FAQs and guides',
        icon       : 'heroicons_outline:support',
        link       : '/apps/help-center',
        useRouter  : true
    }, */
    {
        id         : 'change-theme',
        label      : 'Light/Dark mode',
        description: 'Change the theme',
        icon       : 'heroicons_outline:moon',
    },
    {
        id         : 'mailto',
        label      : 'Support Email',
        description: 'Please contact us here',
        icon       : 'heroicons_outline:envelope',
        link       : 'mailto:support@distro-beat.com',
        newTab     : true,
        useRouter  : false
    },
];
