import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { RoleGuard } from 'app/core/auth/guards/role.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { HomeRedirectComponent } from 'app/shared/components/home-redirect/home-redirect.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path
    {
        path: '',
        pathMatch: 'full',
        component: HomeRedirectComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
            { path: 'sign-up', loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule) },
            { path: 'platform-setup', loadChildren: () => import('app/modules/auth/platform-setup/platform-setup.module').then(m => m.AuthPlatformSetupModule) },
        ]
    },

    // Auth routes for authenticated users (empty layout)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
            { path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule) },
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            { path: 'verify', loadChildren: () => import('app/modules/auth/verify/verify.module').then(m => m.AuthVerifyModule) },
            { path: 'account-locked', loadChildren: () => import('app/modules/landing/account-locked/account-locked.module').then(m => m.AccountLockedModule) },
            { path: 'terms-changed', loadChildren: () => import('app/modules/landing/terms-changed/terms-changed.module').then(m => m.TermsChangedModule) },
            { path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule) },
            { path: 'info', loadChildren: () => import('app/modules/landing/docs/docs.module').then(m => m.DocsModule) },
        ]
    },

    // Auth routes for authenticated users with normal layout (header, footer, etc.)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {

        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'docs', loadChildren: () => import('app/modules/landing/docs/docs.module').then(m => m.DocsModule) },
        ]
    },

    // User routes
    {
        path: '',
        canActivate: [RoleGuard],
        data: {
            roles: ['user', 'label', 'marketing', 'artist']
        },
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/user/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'releases', loadChildren: () => import('app/modules/user/releases/releases.module').then(m => m.ReleasesModule) },
            { path: 'labels', loadChildren: () => import('app/modules/user/label-management/label-management.module').then(m => m.LabelManagementModule) },
            { path: 'artists', loadChildren: () => import('app/modules/user/label-management/label-management.module').then(m => m.LabelManagementModule) },
            { path: 'analytics', loadChildren: () => import('app/modules/user/analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'services', loadChildren: () => import('app/modules/user/services/services.module').then(m => m.ServicesModule) },
            { path: 'subscriptions', loadChildren: () => import('app/modules/user/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule) },
            { path: 'payments', loadChildren: () => import('app/modules/user/payments/payments.module').then(m => m.PaymentsModule) },
            { path: 'settings', loadChildren: () => import('app/modules/user/settings/settings.module').then(m => m.SettingsModule) },
        ]
    },

    // Partner routes
    {
        path: 'partner',
        canActivate: [RoleGuard],
        data: {
            roles: ['partner']
        },
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: "full" },
            { path: 'dashboard', loadChildren: () => import('app/modules/user/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'user-management', loadChildren: () => import('app/modules/partner/user-management/user-management.module').then(m => m.UserManagementModule) },
            { path: 'release-approval', loadChildren: () => import('app/modules/partner/release-approval/release-approval.module').then(m => m.ReleaseApprovalModule) },
            { path: 'labels', loadChildren: () => import('app/modules/user/label-management/label-management.module').then(m => m.LabelManagementModule) },
            { path: 'analytics', loadChildren: () => import('app/modules/user/analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'subscription-management', loadChildren: () => import('app/modules/partner/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementModule) },
            { path: 'service-management', loadChildren: () => import('app/modules/partner/service-management/service-management.module').then(m => m.ServiceManagementModule) },
            { path: 'payments', loadChildren: () => import('app/modules/partner/payments/payments.module').then(m => m.PartnerPaymentsModule) },
            { path: 'royalties', loadChildren: () => import('app/modules/partner/royalties/royalties.module').then(m => m.PartnerRoyaltiesModule) },
            { path: 'settings', loadChildren: () => import('app/modules/partner/partner-settings/partner-settings.module').then(m => m.PartnerSettingsModule) },
        ]
    },

    // Admin routes
    {
        path: 'admin',
        canActivate: [RoleGuard],
        data: {
            roles: ['admin']
        },
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: "full" },
            { path: 'dashboard', loadChildren: () => import('app/modules/user/dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'user-management', loadChildren: () => import('app/modules/partner/user-management/user-management.module').then(m => m.UserManagementModule) },
            { path: 'release-approval', loadChildren: () => import('app/modules/partner/release-approval/release-approval.module').then(m => m.ReleaseApprovalModule) },
            { path: 'analytics', loadChildren: () => import('app/modules/user/analytics/analytics.module').then(m => m.AnalyticsModule) },
            { path: 'payments', loadChildren: () => import('app/modules/partner/payments/payments.module').then(m => m.PartnerPaymentsModule) },
            { path: 'royalties', loadChildren: () => import('app/modules/partner/royalties/royalties.module').then(m => m.PartnerRoyaltiesModule) },
            { path: 'payout', loadChildren: () => import('app/modules/admin/payout/payout.module').then(m => m.PayoutModule) },
            { path: 'partner-management', loadChildren: () => import('app/modules/admin/partner-management/partner-management.module').then(m => m.PartnerManagementModule) },
            { path: 'maintenance', loadChildren: () => import('app/modules/admin/maintenance/maintenance.module').then(m => m.MaintenanceModule) },
        ]
    },

    {
        path: 'payout',
        canActivate: [RoleGuard],
        data: {
            roles: ['admin']
        },
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: '', loadChildren: () => import('app/modules/admin/payout/payout.module').then(m => m.PayoutModule) },
        ]
    },


];
