import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbService } from 'xng-breadcrumb';
import { PlatformService } from 'app/core/platform/platform.service';
import { Title } from '@angular/platform-browser';
import { FuseConfigService } from '@fuse/services/config';
import { NavigationError, Router, RouterEvent } from '@angular/router';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { environment } from 'environments/environment';
import { PlatformSettings } from 'app/core/platform/platform.types';
import { Subject, combineLatest, filter, take, takeUntil } from 'rxjs';
import { TimeagoIntl } from 'ngx-timeago';
import { TranslocoService } from '@ngneat/transloco';
import { strings as englishStrings } from "ngx-timeago/language-strings/en";
import { strings as germanStrings } from "ngx-timeago/language-strings/de";
import { UserService } from './core/user/user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    favIcon: HTMLLinkElement = document.querySelector('#favicon');
    splashscreenLogo: HTMLImageElement = document.querySelector('#splashscreenLogo');
    platform: PlatformSettings;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    //Injecting the BreadcrumbService is required for the breadcrumb to show up after an app reload
    constructor(
        private breadcrumbService: BreadcrumbService,
        private platformService: PlatformService,
        private fuseConfigService: FuseConfigService,
        private titleService: Title,
        private router: Router,
        private ccService: NgcCookieConsentService,
        private intl: TimeagoIntl,
        private translocoService: TranslocoService,
        private userService: UserService,
    ) { }
    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
    }

    ngOnInit(): void {
        this.fuseConfigService.config = {
            scheme: 'light',
            theme: 'theme-default'
        };

        combineLatest([this.platformService.platform$, this.userService.user$]).pipe(take(1)).subscribe({
            next: ([platform, user]) => {
                if (!user || !platform) {
                    return;
                }

                if (user.acceptedTermsVersion !== platform.termsOfServiceVersion) {
                    this.router.navigate(['terms-changed']);
                    return;
                }
            }
        });



        if (this.translocoService.getActiveLang() === 'deu') {
            this.intl.strings = germanStrings;
        } else {
            this.intl.strings = englishStrings;
        }

        this.platformService.get().subscribe({
            next: platform => {
                this.platform = platform;
                this.setTitle(platform.pageTitle);
                this.favIcon.href = this.platformService.getFileSrc(platform.favicon, 'favicon');
                this.splashscreenLogo.src = this.platformService.getFileSrc(platform.loginLogoDark, 'logo');
                this.splashscreenLogo.classList.remove('hidden');

                //Not a typo
                this.fuseConfigService.config = {
                    scheme: localStorage.getItem("user-theme") ?? platform.theme,
                    theme: platform.accent
                };

                try {
                    this.ccService.destroy();
                } catch (e) {
                    console.error(e);
                }

                let ccConfig = this.ccService.getConfig();
                ccConfig.content.privacyPolicyLinkText = "Privacy Policy";
                ccConfig.content.privacyPolicyHref = platform.privacyPolicyUrl;
                ccConfig.content.policy = "Privacy Policy";
                ccConfig.content.href = platform.privacyPolicyUrl;

                ccConfig.cookie = {
                    domain: window.location.hostname,
                };

                ccConfig.elements = {
                    messagelink: `
                    <span id="cookieconsent:desc" class="cc-message">{{message}} Learn more in our
                      <a aria-label="Learn more about our privacy policy" tabindex="1" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLinkText}}</a>.
                    </span>
                    `,
                };

                //if (environment.production) {
                this.ccService.init(ccConfig);
                let policyElement = document.querySelector('.cc-bottom');
                if (policyElement) {
                    policyElement.remove();
                }
                //}
            },
            error: error => {
                console.error(error);
                if (error.status === 404) {
                    this.router.navigate(['/platform-setup']);
                }
            }
        })

        // subscribe to cookieconsent observables to react to main events
        this.ccService.popupOpen$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            () => {
            });

        this.ccService.popupClose$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            () => {
            });

        this.ccService.initializing$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (event: NgcInitializingEvent) => {
                if (event.status === "allow") {
                    this.grantCookieConsent();
                } else if (event.status === "deny") {
                    this.revokeCookieConsent();
                }
            });

        this.ccService.initialized$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            () => {
            });

        this.ccService.initializationError$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (event: NgcInitializationErrorEvent) => {
                console.log(`cc initializationError: ${JSON.stringify(event.error?.message)}`);
            });

        this.ccService.statusChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (event: NgcStatusChangeEvent) => {
                if (event.status === 'allow') {
                    this.grantCookieConsent(false);
                } else if (event.status === 'deny') {
                    this.revokeCookieConsent(false);
                }
            });

        this.ccService.revokeChoice$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            () => {
                this.revokeCookieConsent();
            });

        this.ccService.noCookieLaw$.pipe(takeUntil(this._unsubscribeAll)).subscribe(
            (event: NgcNoCookieLawEvent) => {
                this.grantCookieConsent();
            });

            this.router.events.pipe(
                takeUntil(this._unsubscribeAll),
                filter((e) => e instanceof RouterEvent),
                filter((e) => e.constructor.name === 'NavigationError')
            ).subscribe((e: NavigationError) => {
                console.error(`Could not load URL "${e.url}": ${e.error}`);
                this.router.navigate(['/']);
            });
    }

    private grantCookieConsent(implicit: boolean = true) {
        let consent = {
            status: 'granted',
        };
        let event = new CustomEvent('consent_update', { detail: consent });
        document.dispatchEvent(event);

        if (!implicit) {
            this.setConsentCookie("allow");
        }
    }

    private revokeCookieConsent(implicit: boolean = true) {
        let consent = {
            status: 'revoked',
        };
        let event = new CustomEvent('consent_update', { detail: consent });
        document.dispatchEvent(event);

        if (!implicit) {
            this.setConsentCookie("deny");
        }
    }

    private setConsentCookie(status: "allow" | "deny") {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1);
        document.cookie = `cc_status=${status}; expires=${date.toUTCString()}; path=/; SameSite=Lax; Secure; Domain=${environment.cookieDomain}`;
        document.cookie = `cc_time=${new Date().getTime()}; expires=${date.toUTCString()}; path=/; SameSite=Lax; Secure; Domain=${environment.cookieDomain}`;
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
