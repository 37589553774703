import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations/public-api';
import { PlatformService } from 'app/core/platform/platform.service';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { AuthService } from 'app/core/auth/auth.service';
import { Router } from '@angular/router';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { SendNotificationModal } from 'app/modules/partner/user-management/send-notification-modal/send-notification-modal.component';
import { MatDialog } from '@angular/material/dialog';

export type ResultSet = {
    id: string;
    label: string;
    results: any;
}

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
    styles: [`
        .mdc-list-item__primary-text {
            width: 100%;
        }
    `],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 100;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('barSearchInput') barSearchInput: ElementRef;
    @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

    opened: boolean = false;
    thisUser: User;
    resultSets: ResultSet[];
    searchControl: FormControl = new FormControl();
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        public platformService: PlatformService,
        private userService: UserService,
        private _authService: AuthService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    @HostListener('window:keydown', ['$event'])
    keyEvent(e: KeyboardEvent) {
        if ((e.metaKey || e.ctrlKey) && e.key === "f") {
            e.preventDefault();
            this.open();
            setTimeout(() => {
                this.barSearchInput.nativeElement.focus();
            });
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        this.userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe(user => {
            this.thisUser = user;
        });

        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this._httpClient.post('api/common/search', { query: value })
                    .subscribe((resultSets: any) => {

                        // Store the result sets
                        this.resultSets = resultSets;

                        // Execute the event
                        this.search.next(resultSets);
                        setTimeout(() => this.matAutocomplete?.options?.first?.focus("keyboard"));
                    });
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Listen for escape to close the search
        // if the appearance is 'bar'
        if (this.appearance === 'bar') {
            // Escape
            if (event.code === 'Escape') {
                // Close the search
                this.close();
            }
        }
    }

    preventDefault(event) {
        if (event && event.preventDefault) {
            event?.preventDefault();
            event?.stopPropagation();
        }
        this._changeDetectorRef.markForCheck();
    }

    goToReleases(user: User, event) {
        this.preventDefault(event);
        this.close();
        this._router.navigate(
            ['/'+this.thisUser.role+'/release-approval'],
            {
              queryParams: { user: user.uuid },
              queryParamsHandling: 'merge' }
            );
    }

    goToAnalytics(user: User, event) {
        this.preventDefault(event);
        this.close();
        this._router.navigate(
            ['/'+this.thisUser.role+'/analytics'],
            {
              queryParams: { user: user.uuid },
              queryParamsHandling: 'merge' }
            );
    }

    goToUserManagement(user: User, event) {
        this.preventDefault(event);
        this.close();
        this._router.navigate(
            ['/'+this.thisUser.role+'/user-management/list/'+user.uuid],
        );
    }

    goToMarketingDeals(user: User, event) {
        this.preventDefault(event);
        this.close();
        this._router.navigate(
            ['/'+this.thisUser.role+'/user-management/marketing-deals/'+user.uuid],
        );
    }

    goToExpenses(user: User, event) {
        this.preventDefault(event);
        this.close();
        this._router.navigate(
            ['/'+this.thisUser.role+'/user-management/user-expenses/'+user.uuid],
        );
    }

    signInAsUser(user: User, event) {
        this.preventDefault(event);
        this.close();
        console.log('signing in as user', user);
        this._authService.signInAsUser(user).subscribe(() => {
            this._router.navigate(['/']);
        })
    }

    onSelectUser(user: User, event?) {
        console.log('onSelect', user, event);
        this.signInAsUser(user, event);
    }

    openNotificationModal(user: User, event?) {
        this.preventDefault(event);
        this._matDialog.open(SendNotificationModal, {
            autoFocus: false,
            data: {
                userId: user.uuid,
                language: user.personalInformation.language
            }
        })
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
        setTimeout(() => {
            this.barSearchInput.nativeElement.focus();
        });
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
