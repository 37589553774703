.xng-breadcrumb-trail, .xng-breadcrumb-link, .xng-breadcrumb-separator {
    color: white !important;
    font-size: 1rem;
}

.footer-item + .footer-item::before {
    content: '•';
    margin-right: 8px;
}

.mat-mdc-icon-button img.h-8 {
    height: 2rem!important;
}
