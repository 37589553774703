import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { defaultNavigation, adminCompactNavigation, futuristicNavigation, horizontalNavigation, partnerCompactNavigation, userCompactNavigation } from './navigation.data';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation>
    {
        //Check if the navigation data is already available
        let navigation: Navigation;
        this._navigation.pipe(take(1)).subscribe(nav => navigation = nav);
        if (navigation)
        {
            return of(navigation);
        }

        let navData: Navigation = {
            adminCompact: adminCompactNavigation,
            default: defaultNavigation,
            futuristic: futuristicNavigation,
            horizontal: horizontalNavigation,
            partnerCompact: partnerCompactNavigation,
            userCompact: userCompactNavigation
        };

        this._navigation.next(navData);
        return of(navData);
    }
}
