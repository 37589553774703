import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    errorCountMap: Map<string, number> = new Map<string, number>();

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        ) {
        console.log('GlobalErrorHandler initialized');
    }
    handleError(error: any) {
        if (error?.name === 'HttpErrorResponse' || error.message.includes("HttpErrorResponse") || error instanceof HttpErrorResponse) {
            console.warn("Network error");
            return;
        }
        console.error('error', error);

        let existingError = this.errorCountMap.get(error.message);
        if (existingError) {
            this.errorCountMap.set(error.message, existingError + 1);
        } else {
            this.errorCountMap.set(error.message, 1);
        }

        if (existingError && existingError >= 1) {
            return;
        }

        this.httpClient.post('api/error', {url: this.router?.url ?? window.location.href, name: error.name, message: error.message, stack: error.stack}).subscribe();
    }
}
