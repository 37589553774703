<!-- Global background -->
<svg class="-z-1 absolute inset-0 print:hidden"
     height="100%" preserveAspectRatio="xMidYMax slice" viewBox="0 0 960 540" width="100%" xmlns="http://www.w3.org/2000/svg">
    <g class="opacity-40 text-gray-200 dark:text-gray-800" fill="none" stroke="currentColor" stroke-width="100">
        <circle cx="196" cy="23" r="234"></circle>
        <circle cx="790" cy="491" r="234"></circle>
    </g>
</svg>

<!-- Navigation -->
<fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'" [name]="'mainNavigation'" [navigation]="navigationItems"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img class="p-8" [src]="platformService.getFileSrc(platform?.navbarLogoDark, 'logo')" alt="Logo image">
        </div>
    </ng-container>
    <ng-container fuseVerticalNavigationContentFooter>
        <!-- User -->
        <div *ngIf="adminUser" class="flex flex-col items-center justify-center text-center w-full px-1 py-4 text-sm hover:cursor-pointer" (click)="backToAdmin()"
        [matTooltip]="'Log back into the admin account [Ctrl+D]'" matTooltipClass="tooltip-align-center">
            <div class="text-hint w-full mb-2">Go back to</div>
            <div class="flex flex-col w-full overflow-hidden">
                <div class="flex flex-col justify-center items-center text-center">
                    <img *ngIf="adminUser?.personalInformation?.avatar" class="h-10 w-10 rounded"
                            [src]="userManagementService.getUserAvatar(adminUser)">
                    <div *ngIf="!adminUser.personalInformation.avatar"
                        class="flex items-center justify-center rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200 h-10 w-10">
                        {{adminUser?.personalInformation.firstName.charAt(0)}}
                    </div>
                    <div class="flex flex-col my-auto mt-1">
                        <span>{{userManagementService.getUserName(adminUser)}}</span>
                        <span *ngIf="userManagementService.getUserCompany(adminUser)"
                                class="text-hint">{{userManagementService.getUserCompany(adminUser)}}</span>
                    </div>
                </div>
                <!-- <div class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
                    {{adminUser.personalInformation?.firstName}} {{adminUser.personalInformation?.lastName}}
                </div>
                <div class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
                    {{adminUser.email}}
                </div> -->
            </div>
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0 relative">

    <!-- Header -->
    <div
        class="dark relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card print:hidden bg-"
        [ngClass]="{'bg-green-900': user?.role === 'admin'}">
        <!-- Navigation toggle button -->
        <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>

        <xng-breadcrumb [autoGenerate]="false" class="hidden md:block ml-2" separator=">">
            <ng-container *xngBreadcrumbItem="let breadcrumb">
                <ng-container
                    *ngIf="breadcrumb.charAt(0) !== breadcrumb.charAt(0).toUpperCase(); else breadcrumbNoKey">{{
                    breadcrumb | transloco }}</ng-container>
                <ng-template #breadcrumbNoKey>{{truncate(breadcrumb, 20)}}</ng-template>
            </ng-container>
        </xng-breadcrumb>

        <div class="ml-auto flex">
            <div *ngIf="(user.role === 'user' || user.role === 'label' || user.role === 'marketing' || user.role === 'artist')"
                class="text-lg text-gray-300 font-light rounded-2xl px-3 py-1 mt-1 leading-4 border-gray-300">
                <div class="text-base font-bold">{{user.balance ?? 0 | currency: platform.currency:'symbol':'1.2-2' }}</div>
                <div class="text-md text-gray-400">{{'balance' | transloco}}</div>
            </div>

            <!-- Components -->
            <div class="flex items-center justify-self-end pl-2 space-x-1 sm:space-x-2">
                <languages></languages>
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                <search *ngIf="user.role === 'admin' || user.role === 'partner'" [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
                <notifications></notifications>
                <user></user>
            </div>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto mb-16 md:mb-0">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div @slideInBottom @slideOutBottom *ngIf="!(audioPlayerService.isVisible$ | async)"
        class="absolute bottom-0 flex flex-0 items-center border-t bg-transparent print:hidden text-md w-full h-18 md:h-8 px-4 md:px-6 z-49 flex-col-reverse md:flex-row flex-wrap md:flex-nowrap">
        <div class="flex list-none flex-wrap justify-center md:justify-between mb-2 md:mb-0 w-full md:w-1/3 mt-1">
            <div class="flex items-center">
                <span class="text-secondary">DistroBeat GmbH &copy; {{currentYear}}</span>
            </div>
        </div>

        <nav aria-label="footer" class="sticky bottom-0 w-full md:w-1/3 mt-1 text-secondary">
            <ul class="list-none flex flex-wrap col-span-12 justify-center mb-2 md:mb-0 gap-2">
                <li class="footer-item"><a class="text-primary-700" *ngIf="platform?.imprintUrl" [href]="platform?.imprintUrl" target="_blank">{{'imprint' | transloco}}</a></li>
                <li class="footer-item"><a class="text-primary-700" *ngIf="platform?.termsOfServiceUrl" [href]="platform?.termsOfServiceUrl" target="_blank">{{'terms-of-condition-short' | transloco}}</a></li>
                <li class="footer-item"><a class="text-primary-700" *ngIf="platform?.privacyPolicyUrl" [href]="platform?.privacyPolicyUrl" target="_blank">{{'privacy-policy-short' | transloco}}</a></li>

            </ul>
        </nav>
    </div>
    <!-- <app-audio-player class="sticky z-50" [ngClass]="{'bottom-8 w-5/6 mx-auto rounded-sm': layoutService.isSmall, 'bottom-0 w-full': !layoutService.isSmall}"></app-audio-player>-->
    <app-audio-player class="sticky z-50 bottom-0 w-full"></app-audio-player>

</div>


